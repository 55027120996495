import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoomSection, RelatedRooms, ReviewsSlider, ReviewForm, GalleryStruttura } from "../components";

const RoomDetails = () => {
  const { id } = useParams(); // Ottieni l'ID dalla rotta

  // Recupera le stanze dallo stato Redux
  const rooms = useSelector((state) => state.rooms.rooms || []);

  // Trova la stanza corrispondente all'id
  const room = rooms.find((room) => room.roomId === parseInt(id, 10));

  if (!room) {
    return <p>Stanza non trovata</p>;
  }

  const {
    roomId,
    roomName,
    description,
    roomType,
    pricePerNight,
    maxGuests,
    amenities,
    images,
  } = room;

  return (
    <div className="room-details w-full lg:max-w-7xl">
      {/* Passa i dati della stanza come props */}
      <RoomSection
        roomId={roomId}
        roomName={roomName}
        description={description}
        roomType={roomType}
        pricePerNight={pricePerNight}
        maxGuests={maxGuests}
        amenities={amenities}
        images={images} // Assicurati che `room.images` contenga le immagini corrette
      />

      {/* Passa l'ID della stanza attuale e le stanze come props */}
      <RelatedRooms currentRoomId={room.roomId} rooms={rooms} />

      <div className="mt-16 mb-16 flex text-center justify-center">
        <ReviewsSlider />
      </div>

      <div className="mt-16 mb-16 flex text-center justify-center">
        <GalleryStruttura />
      </div>

      <div className="mb-16">
        <ReviewForm />
      </div>
      

    
    </div>
  );
};

export default RoomDetails;
