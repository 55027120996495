import {MdOutlineWifi, MdFreeBreakfast, MdTv, TbAirConditioning, MdOutlinePets, MdOutlineRoomService, MdOutlineAirportShuttle, MdLuggage, MdOutlineLocalLaundryService , BiHandicap, MdOutlineSmokeFree} from "../assets/icons";

export const isActiveStyles =
  " text-2xl p-2 rounded-md text-primary font-semibold text-headingColor px-4 py-2 duration-100 transition-all ease-in-out bg-textColor";
  // p-2 hover:rounded-md hover:bg-orange-500 text-xl hover:text-white hover:scale-105 text-textColor

export const isNotActiveStyles =
  " text-xl p-2 hover:rounded-md text-textColor hover:text-headingColor duration-100 px-4 py-2 transition-all ease-in-out";

  export const services = [
    { id: 1, title: "Wi-Fi", category: "wifi", icon: MdOutlineWifi },
    { id: 2, title: "Colazione inclusa", category: "colazione", icon: MdFreeBreakfast },
    { id: 3, title: "TV", category: "tv", icon: MdTv },
    { id: 4, title: "Aria condizionata", category: "aria_condizionata", icon: TbAirConditioning },
    { id: 5, title: "Pet-friendly", category: "pet_friendly", icon: MdOutlinePets },
    { id: 6, title: "Minibar", category: "minibar", icon: MdOutlineRoomService },
    { id: 7, title: "Servizio in camera", category: "servizio_in_camera", icon: MdOutlineRoomService },
    { id: 8, title: "Trasporto da/per l'aeroporto", category: "trasporto_aeroporto", icon: MdOutlineAirportShuttle },
    { id: 9, title: "Deposito bagagli", category: "deposito_bagagli", icon: MdLuggage },
    { id: 10, title: "Lavanderia", category: "lavanderia", icon: MdOutlineLocalLaundryService },
    { id: 11, title: "Servizi per disabili", category: "servizi_disabili", icon: BiHandicap },
    { id: 12, title: "Camere non fumatori", category: "non_fumatori", icon: MdOutlineSmokeFree },
  ];
  

  
export const roomTypes = [
  { id: 1, type: "Singola" },
  { id: 2, type: "Doppia" },
  { id: 3, type: "Tripla" },
  { id: 4, type: "Quadrupla" },
  { id: 5, type: "Suite" },
  { id: 6, type: "Appartamento" },
  { id: 7, type: "Villa" },
  { id: 8, type: "Chalet" },
  { id: 9, type: "Bungalow" },
  { id: 10, type: "Casa Vacanze" },
  { id: 11, type: "Monolocale" },
  { id: 12, type: "Penthouse" },
  { id: 13, type: "Loft" },
  { id: 14, type: "Alloggio" },
  { id: 15, type: "Altro" },
];