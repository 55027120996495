import { useState, React } from "react";
import { IoInformationCircleOutline } from "../assets/icons/index";
import { Link } from "react-router-dom";


const AccordionInfo = () => {
  // Impostare la sezione "section1" come quella aperta di default
  const [openSection, setOpenSection] = useState("section1");

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div
      id="accordion-collapse"
      data-accordion="collapse"
      className="bg-white rounded-xl"
    >
      {/* Condizioni Generali di Servizio */}
      <h2 id="accordion-collapse-heading-1 rounded-xl">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100 gap-3"
          aria-expanded={openSection === "section1"}
          onClick={() => toggleSection("section1")}
          aria-controls="accordion-collapse-body-1"
        >
          <span>Condizioni Generali di Servizio</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 shrink-0 transition-transform duration-200 ${
              openSection === "section1" ? "rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            aria-hidden="true"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${openSection === "section1" ? "" : "hidden"}`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <div className="p-5 border border-b-0 border-gray-200">
          <ul className="list-disc pl-5 text-gray-700">
            <li>
              <strong>Check-in:</strong> dalle 15:00 alle 19:00
            </li>
            <li>
              <strong>Check-out:</strong> entro le 10:00
            </li>
            <li>
              <strong>Politiche di cancellazione:</strong> Non rimborsabile
            </li>
            <li>
              <strong>Animali ammessi:</strong> Sì, previa comunicazione per
              animali di piccola taglia
            </li>
            <li>
              <strong>Divieto di fumo:</strong> Le camere sono non fumatori. È
              consentito fumare solo nelle aree designate
            </li>
            <li>Non è permesso organizzare feste o eventi</li>
            <li>
              <strong>Culle e letti aggiuntivi:</strong> Disponibilità su
              richiesta
            </li>
          </ul>
          <div className="bg-primary text-lighttextGray p-2 text-sm rounded-xl shadow-inner mt-4">
            <IoInformationCircleOutline className="inline-block text-black text-lg mr-2" />
            Per maggiori info sui Termini Generali della nostra struttura, ti
            invitiamo a consultare la pagina{" "}
            <Link to={"/termini-e-condizioni"} className="text-blue-600">
              Termini e Condizioni
            </Link>
          </div>
        </div>
      </div>

      {/* Informazioni Prenotazione e Supplementi */}
      <h2 id="accordion-collapse-heading-2">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 hover:bg-gray-100 gap-3"
          aria-expanded={openSection === "section2"}
          onClick={() => toggleSection("section2")}
          aria-controls="accordion-collapse-body-2"
        >
          <span>Informazioni Prenotazione e Supplementi</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 shrink-0 transition-transform duration-200 ${
              openSection === "section2" ? "rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            aria-hidden="true"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${openSection === "section2" ? "" : "hidden"}`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <div className="p-5 border border-t-0 border-gray-200">
          <ul className="list-disc pl-5 text-gray-700">
            <li>I bambini sotto i 5 anni soggiornano gratuitamente</li>
            <li>Disponibilità di culle gratuite</li>
            <li>
              Nelle aree comuni sono disponibili:
              <ul className="list-disc pl-5">
                <li>Cucina con microonde</li>
                <li>Macchinetta del caffè</li>
                <li>Frigorifero</li>
                <li>Lavastoviglie</li>
                <li>Scalda biberon</li>
              </ul>
            </li>
            <li>
              <strong>Pagamenti:</strong> Il saldo deve essere effettuato al
              momento del check-in. Sono accettati pagamenti con carta, bonifico
              o contanti
            </li>
            <li>
              Eventuali spese extra durante il soggiorno saranno saldate al
              momento del check-out
            </li>
            <li>
              Modifiche alla prenotazione sono soggette a disponibilità e
              potrebbero comportare costi aggiuntivi
            </li>
            <li>
              Gli ospiti sono responsabili per eventuali danni alla struttura o
              agli arredi durante il soggiorno
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AccordionInfo;
