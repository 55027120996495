import React, { useState, useEffect } from "react";
import { createBooking } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { getAllRooms } from "../api";
import { setAllRooms } from "../context/actions/roomActions";

import {
  alertDanger,
  alertSuccess,
  alertNull,
} from "../context/actions/alertAction";

const DBBookingManual = () => {
  const rooms = useSelector((state) => state.rooms.rooms || []);
  // Stati per gestire i dati del form
  const [userId, setUserId] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomName, setRoomName] = useState("");
  const [roomType, setRoomType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    getAllRooms()
      .then((data) => {
        dispatch(setAllRooms(data));
      })
      .catch((error) => {
        console.error("Errore nel caricamento delle stanze:", error);
      });
  }, [dispatch]);

  // Funzione per gestire l'invio del form
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedStartDate = new Date(startDate)
      .toLocaleDateString("it-IT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-"); // Sostituisci gli slash con trattini
    const formattedEndDate = new Date(endDate)
      .toLocaleDateString("it-IT", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    const bookingData = {
      userId,
      roomId,
      roomName,
      roomType,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      totalAmount: Math.round(totalAmount * 100),
      customer: {
        name: customerName,
        email: customerEmail,
        phone: customerPhone,
      },
    };

    setIsLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      const result = await createBooking(bookingData);
      dispatch(alertSuccess("Prenotazione effettuata con successo!"));
      resetForm(); // Reset del form
      console.log(result);
    } catch (err) {
      dispatch(alertDanger("Errore nell'invio della prenotazione"));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
    // Resetta l'alert dopo secondi
    setTimeout(() => {
      dispatch(alertNull());
    }, 3000);
  };

  // Funzione per reset del form
  const resetForm = () => {
    setUserId("");
    setRoomId("");
    setRoomName("");
    setRoomType("");
    setStartDate("");
    setEndDate("");
    setTotalAmount("");
    setCustomerName("");
    setCustomerEmail("");
    setCustomerPhone("");
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Crea Prenotazione Manuale
      </h2>

      {/* Gestione degli alert tramite Redux */}
      <div className="text-center mb-4">
        {successMessage && <p className="text-green-500">{successMessage}</p>}
        {error && <p className="text-red-500">{error}</p>}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="customerName"
            className="block text-sm font-medium text-gray-700"
          >
            Nome Cliente
          </label>
          <input
            type="text"
            id="customerName"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="customerEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Email Cliente
          </label>
          <input
            type="email"
            id="customerEmail"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="customerPhone"
            className="block text-sm font-medium text-gray-700"
          >
            Telefono Cliente
          </label>
          <input
            type="text"
            id="customerPhone"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="userId"
            className="block text-sm font-medium text-gray-700"
          >
            Provenienza Utente
          </label>
          <input
            type="text"
            id="userId"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="roomId"
            className="block text-sm font-medium text-gray-700"
          >
            Seleziona Stanza
          </label>
          <select
            id="roomId"
            value={roomId}
            onChange={(e) => {
              const selectedRoom = rooms.find(
                (room) => room.roomId.toString() === e.target.value
              );
              setRoomId(e.target.value);
              if (selectedRoom) {
                setRoomName(selectedRoom.roomName);
                setRoomType(selectedRoom.roomType);
              }
            }}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Seleziona una stanza</option>
            {rooms.map((room) => (
              <option key={room.roomId} value={room.roomId}>
                {room.roomName} (ID: {room.roomId})
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="roomName"
            className="block text-sm font-medium text-gray-700"
          >
            Nome Stanza
          </label>
          <input
            type="text"
            id="roomName"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="roomType"
            className="block text-sm font-medium text-gray-700"
          >
            Tipo Stanza
          </label>
          <input
            type="text"
            id="roomType"
            value={roomType}
            onChange={(e) => setRoomType(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="startDate"
            className="block text-sm font-medium text-gray-700"
          >
            Data Inizio
          </label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="endDate"
            className="block text-sm font-medium text-gray-700"
          >
            Data Fine
          </label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="totalAmount"
            className="block text-sm font-medium text-gray-700"
          >
            Importo Totale (€)
          </label>
          <input
            type="number"
            id="totalAmount"
            value={totalAmount}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                setTotalAmount(value);
              }
            }}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className={`w-full py-2 px-4 rounded-md text-white font-semibold ${
            isLoading ? "bg-gray-500" : "bg-blue-600 hover:bg-blue-700"
          } focus:outline-none focus:ring-2 focus:ring-blue-500`}
        >
          {isLoading ? "Inviando..." : "Invia Prenotazione"}
        </button>
      </form>
    </div>
  );
};

export default DBBookingManual;
