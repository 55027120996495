import React, { useState, useRef, useCallback } from "react";
import "react-image-lightbox/style.css"; // Importa gli stili del lightbox
import Lightbox from "react-image-lightbox";
import { colazione2, colazine, fronteAngolo, fronteGrandangolo, frontePiatto, pianoCucina, pianerottolo, salaCucina, scale } from "../assets/index";

const GalleryStruttura = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0);
  const imageRefs = useRef([]);
  const containerRef = useRef(null);
  const isAnimating = useRef(false); // Variabile per controllare se è in corso un'animazione

  const images = [
    { url: frontePiatto, alt: "Placeholder Image 1", title: "Foto 1" },
    { url: fronteAngolo, alt: "Placeholder Image 2", title: "Foto 2" },
    { url: fronteGrandangolo, alt: "Placeholder Image 3", title: "Foto 3" },
    { url: pianerottolo, alt: "Placeholder Image 4", title: "Foto 4" },
    { url: salaCucina, alt: "Placeholder Image 5", title: "Foto 5" },
    { url: scale, alt: "Placeholder Image 6", title: "Foto 6" },
    { url: colazine, alt: "Placeholder Image 7", title: "Foto 7" },
    { url: colazione2, alt: "Placeholder Image 8", title: "Foto 8" },
    { url: pianoCucina, alt: "Placeholder Image 9", title: "Foto 9" },
  ];

  const handlePrevClick = useCallback(() => {
    if (isAnimating.current) return; // Evita di eseguire la funzione se è in corso un'animazione
    isAnimating.current = true; // Imposta l'animazione come in corso

    setCurrentIndex(prevIndex => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : images.length - 1;
      containerRef.current.scrollBy({
        left: -imageRefs.current[0].offsetWidth,
        behavior: "smooth",
      });
      return newIndex;
    });

    setTimeout(() => {
      isAnimating.current = false; // Reset l'animazione dopo un timeout
    }, 500); // Tempo sufficiente per completare l'animazione
  }, [images.length]);

  const handleNextClick = useCallback(() => {
    if (isAnimating.current) return; // Evita di eseguire la funzione se è in corso un'animazione
    isAnimating.current = true; // Imposta l'animazione come in corso

    setCurrentIndex(prevIndex => {
      const newIndex = prevIndex < images.length - 1 ? prevIndex + 1 : 0;
      containerRef.current.scrollBy({
        left: imageRefs.current[0].offsetWidth,
        behavior: "smooth",
      });
      return newIndex;
    });

    setTimeout(() => {
      isAnimating.current = false; // Reset l'animazione dopo un timeout
    }, 500); // Tempo sufficiente per completare l'animazione
  }, [images.length]);

  const openLightbox = (index) => {
    setLightboxImageIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="w-full flex flex-col items-start">
      <div className="flex flex-col items-start justify-start gap-1">
        <p className="text-2xl text-headingColor font-bold">La Nostra Struttura</p>
        <p className="mb-2 font-normal">
          Esplora il nostro B&B attraverso la galleria fotografica.
          Dalle camere accoglienti agli spazi comuni curati nei dettagli,
          lasciati ispirare dalla nostra ospitalità e dall’atmosfera unica di
          Lamezia Terme.
        </p>
      </div>
      <div className="relative w-full overflow-hidden mb-15">
        {currentIndex > 0 && (
          <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-20 hidden md:block">
            <button onClick={handlePrevClick} className="bg-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:bg-gray-200 hover:scale-105">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path d="M15 18l-6-6 6-6v12z" />
              </svg>
            </button>
          </div>
        )}
        <div ref={containerRef} className="relative w-full flex overflow-x-auto scrollbar-none py-8">
          <div className="flex space-x-6">
            {images.map((image, i) => (
              <SliderCard key={i} image={image} index={i} onClick={() => openLightbox(i)} ref={(el) => (imageRefs.current[i] = el)} />
            ))}
          </div>
        </div>
        {currentIndex < images.length - 1 && (
          <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-20 hidden md:block">
            <button onClick={handleNextClick} className="bg-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg transition duration-300 ease-in-out transform hover:bg-gray-200 hover:scale-105">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                <path d="M9 18l6-6-6-6v12z" />
              </svg>
            </button>
          </div>
        )}
      </div>

      {/* Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxImageIndex].url}
          nextSrc={images[(lightboxImageIndex + 1) % images.length].url}
          prevSrc={images[(lightboxImageIndex + images.length - 1) % images.length].url}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() => setLightboxImageIndex((lightboxImageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setLightboxImageIndex((lightboxImageIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

const SliderCard = React.forwardRef(({ image, index, onClick }, ref) => {
  return (
    <div className="w-64 cursor-pointer rounded-xl bg-headingColor shadow-md flex flex-col items-center justify-center gap-4" onClick={onClick} ref={ref}>
      <img src={image.url} alt={image.alt} loading="lazy" className="w-full h-48 object-cover rounded-md" />
    </div>
  );
});

export default GalleryStruttura;
