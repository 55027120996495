import React, { useState } from "react";
import { FaCloudUploadAlt, MdDelete } from "../assets/icons";
import { Spinner } from "../components";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../config/firebase.config";
import { useDispatch } from "react-redux";
import { alertDanger, alertSuccess, alertNull } from "../context/actions/alertAction"; 
import { motion } from "framer-motion";
import ProgressBar from "./ProgressBar";

const ImageUpload = ( {setImages, images}) => {
 
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  // Funzione per l'upload dell'immagine su Firebase Storage
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    if (files.length === 0) {
      dispatch(alertDanger("Seleziona un'immagine per il caricamento"));
      return;
    }

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      dispatch(alertDanger("Sono accettati solo file PNG e JPEG."));
      return;
    }

    setUploading(true);

    const uploadImage = (file) => {
      return new Promise((resolve, reject) => {
        const storageRef = ref(storage, `images/${Date.now()}-${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(uploadProgress);
          },
          (error) => {
            console.error(error);
            reject("Errore durante il caricamento dell'immagine");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };

    Promise.all(files.map((file) => uploadImage(file)))
      .then((downloadURLs) => {
        setImages((prevImages) => [...prevImages, ...downloadURLs]);
        setUploading(false);
        e.target.value = "";
        dispatch(alertSuccess("Immagini caricate con successo"));
        setTimeout(() => dispatch(alertNull()), 5000);
      })
      .catch((error) => {
        dispatch(alertDanger(error));
        setUploading(false);
      });
  };

  const handleDeleteImage = (imageUrl) => {
    const imageRef = ref(storage, imageUrl);
    deleteObject(imageRef)
      .then(() => {
        setImages(images.filter((img) => img !== imageUrl));
        dispatch(alertSuccess("Immagine eliminata con successo"));
        setTimeout(() => dispatch(alertNull()), 5000);
      })
      .catch((error) => {
        console.log(error);
        dispatch(alertDanger("Errore durante l'eliminazione dell'immagine"));
        setTimeout(() => dispatch(alertNull()), 5000);
      });
  };

  return (
    <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-6 mt-6">
      <div className="mt-4">
        <label className="block mb-2">Carica Immagine</label>

        <div className="w-full bg-card backdrop-blur-md h-370 rounded-md border-2 border-dotted border-gray-300 cursor-pointer">
          {uploading ? (
            <div className="w-full h-full flex flex-col items-center justify-evenly px-24">
              <Spinner />
              <ProgressBar progress={progress} />
            </div>
          ) : (
            <label className="flex flex-col items-center justify-center h-full w-full cursor-pointer">
              <div className="flex flex-col justify-center items-center">
                <FaCloudUploadAlt className="text-4xl text-primary mb-2" />
                <p className="text-lg text-textColor">
                  {images.length > 0 ? "Carica un'altra Immagine" : "Clicca per Caricare un'Immagine"}
                </p>
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageUpload}
                className="w-0 h-0"
              />
            </label>
          )}
        </div>

        {images.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-medium text-headingColor mb-2">Immagini Caricate</h3>
            <div className="flex flex-wrap justify-center">
              {images.map((imageUrl, index) => (
                <div key={index} className="relative mr-2 mb-2 w-48 h-32 overflow-hidden rounded-md">
                  <motion.img
                    whileHover={{ scale: 1.15 }}
                    src={imageUrl}
                    alt="Uploaded"
                    className="w-full h-full object-cover"
                  />
                  <motion.button
                    type="button"
                    className="absolute flex items-center justify-center top-1 right-1 p-1 rounded-full h-10 w-10 bg-red-500 text-white text-sm hover:shadow-md"
                    onClick={() => handleDeleteImage(imageUrl)}
                  >
                    <MdDelete className="h-6 w-6" />
                  </motion.button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
