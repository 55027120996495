import React, { useState } from "react";
import { HiCurrencyEuro } from "../assets/icons";
import { updateRoom, getAllRooms } from "../api"; // Funzione per aggiornare la stanza
import { alertNull, alertSuccess } from "../context/actions/alertAction";
import { useDispatch } from "react-redux";
import { services } from "../utils/styles";
import { setAllRooms } from "../context/actions/roomActions";
import { RoomManager } from "../components"


const EditRoomForm = ({ room, onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentImages, setCurrentImages] = useState(room.images || []); // Mantieni le immagini correnti
  const [newImages, setNewImages] = useState([]); // Per le immagini appena caricate

  const handleRemoveImage = (imageUrl) => {
    setCurrentImages((prev) => prev.filter((img) => img !== imageUrl));
  };

  const handleNewImages = (e) => {
    const files = Array.from(e.target.files);
    setNewImages((prev) => [...prev, ...files]);
  };

  const [formData, setFormData] = useState({
    roomName: room.roomName,
    roomType: room.roomType,
    pricePerNight: room.pricePerNight,
    maxGuests: room.maxGuests,
    description: room.description,
    amenities: room.amenities, // Unisci le amenità in una stringa
    availability: {
      startDate: room.availability.startDate,
      endDate: room.availability.endDate,
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Funzione per gestire il cambio di stato dei pulsanti amenities
  const handleAmenityToggle = (amenity) => {
    const updatedAmenities = formData.amenities.includes(amenity)
      ? formData.amenities.filter((item) => item !== amenity)
      : [...formData.amenities, amenity];
    setFormData({ ...formData, amenities: updatedAmenities });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updatedRoom = {
      ...room,
      ...formData,
      amenities: formData.amenities,
      images: currentImages,
    };

    try {
      await updateRoom(updatedRoom);

      const rooms = await getAllRooms();
      dispatch(setAllRooms(rooms));

      dispatch(
        alertSuccess("Stanza Aggiornata con Successo")
      );
      setTimeout(() => {
        dispatch(alertNull());
      }, 3000);

      onClose();
    } catch (error) {
      console.error("Errore durante l'aggiornamento della stanza:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="z-40 absolute top-3 right-3 left-3 flex items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg w-full max-w-md sm:max-w-lg md:max-w-xl m-4 shadow-xl border-double border-2 border-teal-950"
      >
        <h2 className="text-xl font-semibold mb-4 text-center">
          Modifica Stanza
        </h2>

        {/* Modifica campi del form come Nome, Tipo, Prezzo a Notte */}
        <div className="mb-4">
          <label className="block mb-1">Nome</label>
          <input
            type="text"
            name="roomName"
            value={formData.roomName}
            onChange={handleChange}
            className="border rounded px-3 py-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Tipo</label>
          <input
            type="text"
            name="roomType"
            value={formData.roomType}
            onChange={handleChange}
            className="border rounded px-3 py-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Prezzo a Notte</label>
          <div className="flex items-center">
            <HiCurrencyEuro className="text-lg" />
            <input
              type="number"
              name="pricePerNight"
              value={formData.pricePerNight}
              onChange={handleChange}
              className="border rounded px-3 py-2 w-full ml-2"
              required
            />
          </div>
        </div>

        {/* Restante form per ospiti, descrizione, servizi, date */}
        <div className="mb-4">
          <label className="block mb-1">Ospiti Massimi</label>
          <input
            type="number"
            name="maxGuests"
            value={formData.maxGuests}
            onChange={handleChange}
            className="border rounded px-3 py-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Descrizione</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="border rounded px-3 py-2 w-full"
            required
          ></textarea>
        </div>

        {/* Gestione dei servizi (amenities) */}
        <div className="mb-4">
          <label className="block mb-1">Servizi</label>
          <div className="flex flex-wrap">
            {services.map((service) => (
              <button
                key={service.id}
                type="button"
                onClick={() => handleAmenityToggle(service.title)}
                className={`px-4 py-2 m-1 rounded-md ${
                  formData.amenities.includes(service.title)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                {service.title}
              </button>
            ))}
          </div>
        </div>


        {/* Gestione date di disponibilità */}
        <div className="mb-4">
          <label className="block mb-1">Disponibilità Dal</label>
          <input
            type="date"
            name="startDate"
            value={formData.availability.startDate.slice(0, 10)} // Formato YYYY-MM-DD
            onChange={handleChange}
            className="border rounded px-3 py-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Disponibilità Al</label>
          <input
            type="date"
            name="endDate"
            value={formData.availability.endDate.slice(0, 10)} // Formato YYYY-MM-DD
            onChange={handleChange}
            className="border rounded px-3 py-2 w-full"
            required
          />
        </div>
        <RoomManager 
          setImages={setCurrentImages} 
          images={currentImages} // Passa le immagini attuali
        />

        {/* Bottoni di azione */}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-400 text-white px-4 py-2 rounded-md"
          >
            Annulla
          </button>
          <button
            disabled={isLoading}
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            {isLoading ? "Caricamento..." : "Aggiorna"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditRoomForm;