import React from "react";
import { whatsapp } from "../assets"; // Assicurati che il percorso sia corretto

const WhatsappButton = () => {
  const whatsappUrl = `https://wa.me/+393336754126`; // Modifica il numero con quello corretto

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-8 right-4 h-16 w-16 rounded-full shadow-xl z-40"
    >
      <img
        src={whatsapp}
        alt="WhatsApp logo"
        className="w-full h-full object-cover rounded-full"
      />
    </a>
  );
};

export default WhatsappButton;
