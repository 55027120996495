import React from "react";
import { termsAndConditions } from "../utils/termsConditions";

const TermsConditions = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Termini e Condizioni
      </h1>
      <h2 className="text-center text-xl font-semibold mb-2">
        Regolamento Interno
      </h2>
      <ul className="mb-6">
        <li className="list-disc ml-4">
          <strong>Check-in:</strong> dalle 15:00 alle 19:00
        </li>
        <li className="list-disc ml-4">
          <strong>Check-out:</strong> entro le 10:00
        </li>
        <li className="list-disc ml-4">
          <strong>Politiche di cancellazione:</strong> Non rimborsabile
        </li>
        <li className="list-disc ml-4">
          <strong>Animali ammessi:</strong> Sì, previa comunicazione e per
          animali di piccola taglia.
        </li>
        <li className="list-disc ml-4">
          <strong>Divieto di fumo:</strong> Le camere sono non fumatori. È
          consentito fumare solo nelle aree esterne designate.
        </li>
        <li className="list-disc ml-4">
          <strong>Non è permesso organizzare feste/eventi:</strong> Non è
          permesso organizzare feste di addio al nubilato/celibato o simili.
        </li>
        <li className="list-disc ml-4">
          <strong>Condizioni per culle e letti aggiuntivi:</strong>{" "}
          Disponibilità di culle e letti supplementari
        </li>
      </ul>
      <h2 className="text-center text-xl font-semibold mb-2">
        Informazioni Prenotazione e Supplementi
      </h2>
      <ul className="mb-6">
        <li className="list-disc ml-4">
          <strong>Bambini sotto i 5 anni:</strong> Non pagano
        </li>
        <li className="list-disc ml-4">
          <strong>Disponibilità della culla:</strong> Gratis
        </li>
        <li className="list-disc ml-4">
          <strong>Aree comuni:</strong> Cucina con microonde, macchinetta del
          caffè, frigorifero, lavastoviglie, scalda-biberon
        </li>
        <li className="list-disc ml-4">
          <strong>Pagamenti:</strong> Il saldo del soggiorno deve essere
          effettuato al momento del check-in (Pagamento con carta, bonifico o
          contanti)
        </li>
        <li className="list-disc ml-4">
          <strong>Spese aggiuntive:</strong> Eventuali spese durante il
          soggiorno saranno addebitate al momento del check-out.
        </li>
        <li className="list-disc ml-4">
          <strong>Modifiche alla prenotazione:</strong> Ogni modifica è soggetta
          a disponibilità e può comportare una variazione di prezzo.
        </li>
        <li className="list-disc ml-4">
          <strong>Responsabilità del Cliente:</strong> Gli ospiti sono
          responsabili per eventuali danni causati alla struttura o agli arredi
          durante il soggiorno.
        </li>
      </ul>

      <h2 className="text-center text-xl font-semibold mb-2">
        Termini e Condizioni
      </h2>
      <p>
        <strong>Ultimo aggiornamento:</strong> Novembre 30, 2024
      </p>

      <div
        className="prose prose-sm sm:prose lg:prose-lg text-gray-700"
        dangerouslySetInnerHTML={{
          __html: termsAndConditions,
        }}
      />
    </div>
  );
};

export default TermsConditions;
