import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { AccordionInfo } from "../components";

const RoomDescription = ({ roomId, description, maxGuests, amenities }) => {
 

  return (
    <div className="flex flex-col lg:w-1/2">
      <div className="p-6 rounded-xl mb-2 bg-white">
        <h2 className="font-bold text-2xl text-headingColor">Descrizione</h2>
        <p className="text-gray-600 text-sm">{description}</p>
        <div className="flex gap-2 text-center mt-4">
          <div className="p-2 bg-gray-200 rounded-xl">
            <span className="text-gray-600">Max Ospiti: {maxGuests}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-2 bg-lightOverlay shadow-md rounded-xl">
        <AccordionInfo />
      </div>
      
      
    </div>
  );
};

export default RoomDescription;
