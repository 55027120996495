import React, { useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Lightbox from "react-image-lightbox";


const RoomImagesSection = ({ roomId, images, roomName, roomType }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  

  return (
    <section className="">
      <div className="bg-white rounded-xl shadow-md p-2 mb-2">
        <h2 className="text-4xl text-center font-semibold mb-4 text-headingColor">
          {roomName}
        </h2>
        <h4 className="text-3xl text-center font-normal text-headingColor">
          {roomType}
        </h4>
      </div>
      <div className="space-y-2">
        {images && images.length > 0 ? (
          <>
            {/* Immagine principale */}
            <img
              src={images[photoIndex]}
              alt="Main Room"
              className="w-full shadow-lg h-64 md:h-96 lg:h-650 object-cover rounded-xl cursor-pointer"
              onClick={() => setIsOpen(true)}
            />

            {/* Miniature con scroll laterale */}
            <div className="flex overflow-x-auto space-x-2 mt-4 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className={`mb-4 h-32 w-44 md:h-24 md:w-44 lg:w-48 lg:h-28 object-cover rounded-xl shadow-lg cursor-pointer ${
                    photoIndex === index ? "border-2 border-blue-600" : ""
                  }`}
                  onClick={() => setPhotoIndex(index)}
                />
              ))}
            </div>
          </>
        ) : (
          <p className="text-center text-gray-600">
            Nessuna immagine disponibile
          </p>
        )}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </section>
  );
};

export default RoomImagesSection;
