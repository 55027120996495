import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from "react-router-dom";
import { validateUserJWTToken } from './api';
import { app } from './config/firebase.config';
import { Main, Login, Dashboard } from "./containers";
import { setUserDetails } from './context/actions/userActions';
import { motion } from 'framer-motion';
import { fadeInOut } from './animations';
import { Alert, CheckOutSuccess, MainLoader, UsersBooking, ScrollToTop } from './components';


const App = () => {
  const firebaseAuth = getAuth(app);
  const [isLoading, setIsLoading] = useState(true);  // Set iniziale a true per il caricamento
  const alert = useSelector(state => state.alert);
  const [isAuthenticated, setIsAuthenticated] = useState(false);  // Stato per controllo autenticazione
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();
  const adminId = process.env.REACT_APP_ADMIN_ID;
  
  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      firebaseAuth.onAuthStateChanged(async (cred) => {
        if (cred) {
          const token = await cred.getIdToken();
          const data = await validateUserJWTToken(token);
          if (data) {
            dispatch(setUserDetails(data));
            setIsAuthenticated(true);
            // Verifica se l'ID dell'utente è uguale a quello dell'amministratore
            setIsAdmin(data?.user_id === adminId);
          } else {
            setIsAuthenticated(false);
            setIsAdmin(false);  // Se l'utente non è autenticato, non è admin
          }
        }
        setIsLoading(false); // Ferma il caricamento solo quando il processo di autenticazione è completato
      });
    };

    checkAuth();
  }, [firebaseAuth, dispatch, adminId]); // Assicurati che firebaseAuth e dispatch siano passati come dipendenze

  return (
    <div className='w-screen h-auto flex flex-col items-center justify-center overflow-y-hidden'>
      {/* Visualizza loader durante il caricamento */}
      {isLoading && (
        <motion.div 
          {...fadeInOut} 
          className='fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full'
        >
          <MainLoader />
        </motion.div>
      )}
      
      {/* Rotte della tua applicazione */}
      <ScrollToTop />
      <Routes>
        <Route path='/*' element={<Main />} />
        <Route path='/login' element={<Login />} />
        <Route 
          path='/dashboard/*' 
          element={isAuthenticated && isAdmin ? <Dashboard /> : <Navigate to="/login" />} 
        />
        <Route path='/checkout-success' element={<CheckOutSuccess />} />
        <Route path='/user-booking' element={<UsersBooking />} />

      </Routes>

      {/* Mostra l'alert se presente */}
      {alert?.type && <Alert type={alert?.type} message={alert?.message} />}
    </div>
  );
}

export default App;
