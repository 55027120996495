import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { MetaDescription } from "../components";
import {
  sanTeodoro,
  teatroUmberto,
  piazzaMazzini,
  piazzaDarmiAnni90,
  piazzaDarmiAnni80,
  fontanaPiazzaDarmi,
  corsoNumistrannoAnni80,
  corsoNumistrannoAnni50,
  centroStoricoNicastro,
  cattedrale,
  castelloNormannoNotte,
  bastioneMalta2,
  bastioneMalta,
  frontePiatto,
  fronteAngolo,
  fronteGrandangolo,
  pianerottolo,
  salaCucina,
  scale,
  colazine,
  colazione2,
  pianoCucina,
} from "../assets";

// Unisci i due array di immagini
const images = [
  // Primo array
  {
    url: sanTeodoro,
    alt: "Quartiere San Teodoro, Lamezia Terme",
    title: "Quartiere San Teodoro",
    description:
      "Il quartiere San Teodoro di Lamezia Terme è una zona vivace e dinamica, caratterizzata da un mix di tradizione e modernità, che riflette lo spirito della città.",
  },
  {
    url: teatroUmberto,
    alt: "Teatro Umberto, Lamezia Terme",
    title: "Teatro Umberto",
    description:
      "Il Teatro Umberto è un'imponente struttura culturale di Lamezia Terme, conosciuto per la sua storicità e per gli eventi teatrali e musicali che ospita.",
  },
  {
    url: piazzaMazzini,
    alt: "Piazza Mazzini, Lamezia Terme",
    title: "Piazza Mazzini",
    description:
      "Piazza Mazzini è una delle piazze principali di Lamezia Terme, cuore pulsante della vita cittadina, circondata da edifici storici e negozi.",
  },
  {
    url: piazzaDarmiAnni90,
    alt: "Piazza D'Armi negli anni '90, Lamezia Terme",
    title: "Piazza D'Armi negli anni '90",
    description:
      "Piazza D'Armi negli anni '90, una delle piazze più suggestive di Lamezia Terme, che mostra come la città si sia evoluta nel tempo, mantenendo la sua autenticità.",
  },
  {
    url: piazzaDarmiAnni80,
    alt: "Piazza D'Armi negli anni '80, Lamezia Terme",
    title: "Piazza D'Armi negli anni '80",
    description:
      "Un'immagine storica di Piazza D'Armi negli anni '80, che testimonia il dinamismo e le trasformazioni sociali della città durante quel periodo.",
  },
  {
    url: fontanaPiazzaDarmi,
    alt: "Fontana di Piazza D'Armi, Lamezia Terme",
    title: "Fontana di Piazza D'Armi",
    description:
      "La fontana di Piazza D'Armi è uno degli elementi distintivi della piazza, un luogo di ritrovo e di bellezza che riflette la tradizione architettonica di Lamezia Terme.",
  },
  {
    url: cattedrale,
    alt: "Cattedrale di Lamezia Terme",
    title: "Cattedrale di Lamezia Terme",
    description:
      "La Cattedrale di Lamezia Terme, dedicata a San Pietro e Paolo, è un simbolo della fede e della storia religiosa della città, con la sua maestosa facciata e la sua posizione centrale.",
  },
  {
    url: corsoNumistrannoAnni80,
    alt: "Corso Numistranno negli anni '80, Lamezia Terme",
    title: "Corso Numistranno negli anni '80",
    description:
      "Corso Numistranno negli anni '80, una delle arterie principali di Lamezia Terme, che racconta la crescita della città attraverso gli anni.",
  },
  {
    url: corsoNumistrannoAnni50,
    alt: "Corso Numistranno negli anni '50, Lamezia Terme",
    title: "Corso Numistranno negli anni '50",
    description:
      "Una vista storica di Corso Numistranno negli anni '50, testimoniando le prime fasi di modernizzazione della città.",
  },
  {
    url: centroStoricoNicastro,
    alt: "Centro storico di Nicastro, Lamezia Terme",
    title: "Centro storico di Nicastro",
    description:
      "Il centro storico di Nicastro è il cuore culturale di Lamezia Terme, con le sue stradine caratteristiche e il suo ricco patrimonio storico.",
  },
  {
    url: castelloNormannoNotte,
    alt: "Castello Normanno di Lamezia Terme di notte",
    title: "Castello Normanno di notte",
    description:
      "Il Castello Normanno di Lamezia Terme di notte, una delle principali attrazioni turistiche della città, che offre una vista spettacolare e un'atmosfera magica.",
  },
  {
    url: bastioneMalta2,
    alt: "Bastione di Malta, Lamezia Terme",
    title: "Bastione di Malta",
    description:
      "Il Bastione di Malta, una fortificazione storica che testimonia il passato difensivo della città, oggi simbolo di Lamezia Terme.",
  },
  {
    url: bastioneMalta,
    alt: "Bastione di Malta, Lamezia Terme",
    title: "Bastione di Malta",
    description:
      "Il Bastione di Malta, con la sua imponente struttura, è un importante sito storico che rappresenta la resistenza della città nei secoli passati.",
  },
  // Secondo array
  {
    url: frontePiatto,
    alt: "Vista frontale Struttura",
    title: "Vista Frontale Struttura",
    description:
      "Un'immagine che mostra una vista frontale della struttura.",
  },
  {
    url: fronteAngolo,
    alt: "Vista frontale angolare",
    title: "Vista Frontale Angolare",
    description:
      "",
  },
  {
    url: fronteGrandangolo,
    alt: "Vista grandangolare frontale",
    title: "Vista Grandangolare Frontale",
    description:
      "",
  },
  {
    url: pianerottolo,
    alt: "Pianerottolo interno",
    title: "",
    description:
      "",
  },
  {
    url: salaCucina,
    alt: "Sala cucina moderna",
    title: "Sala Cucina",
    description:
      "Un'immagine che ritrae la sala cucina con design moderno e funzionale. Per la colazione dei nostri ospiti",
  },
  {
    url: scale,
    alt: "Scala interna",
    title: "Scala Interna",
    description:
      "Una scala interna che collega i vari piani dell'edificio con eleganza.",
  },
  {
    url: colazine,
    alt: "Colazione apparecchiata",
    title: "",
    description:
      "",
  },
  {
    url: colazione2,
    alt: "Seconda vista colazione apparecchiata",
    title: "",
    description:
      "",
  },
  {
    url: pianoCucina,
    alt: "Piano cucina moderno",
    title: "Piano Cucina",
    description:
      "il nostro piano cucina, progettato per praticità e stile.",
  },
];

const Gallery = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  return (
    <div className="max-w-7xl">
      <MetaDescription
        title="Scopri il B&B Piazza d'Armi | La Gallery"
        description="Esplora la gallery del B&B Piazza d'Armi a Lamezia Terme. Immagini delle nostre camere, spazi comuni e dintorni per il tuo soggiorno perfetto."
        image="./public/images/fronte-piatto.jpg"
        url="https://www.bebpiazzadarmi.it/gallery"
      />
      <div className=" bg-white py-10 px-6 rounded-xl shadow-lg mb-2 ">
        <h1 className="text-3xl md:text-4xl text-center font-bold text-headingColor">
          Le Nostra Gallery
        </h1>
      </div>
      {/* Griglia delle immagini */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => handleImageClick(index)}
          >
            {/* Immagine */}
            <img
              src={image.url}
              alt={image.alt}
              className="w-full h-64 object-cover rounded-xl shadow-lg hover:opacity-80 transition"
            />
            {/* Sovrapposizione con descrizione */}
            <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition flex flex-col items-center justify-center rounded">
              <h2 className="text-white text-lg font-bold text-center px-2">
                {image.title}
              </h2>
              <p className="text-white text-sm text-center px-4">
                {image.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[currentIndex].url}
          nextSrc={images[(currentIndex + 1) % images.length]?.url}
          prevSrc={
            images[(currentIndex + images.length - 1) % images.length]?.url
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
          imageCaption={
            <div>
              <h2 className="text-lg font-bold">
                {images[currentIndex]?.title}
              </h2>
              <p className="text-sm">{images[currentIndex]?.description}</p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Gallery;
