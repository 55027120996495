import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { RxCross1 } from "../assets/icons/index";



const CookieBanner = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieBannerAccepted");
    if (cookieConsent) {
      setCookieAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookieBannerAccepted", true);
    setCookieAccepted(true);
  };

  const rejectCookies = () => {
    Cookies.set("cookieBannerAccepted", true);
    setCookieAccepted(true);
  };

  const closeBanner = () => {
    setCookieAccepted(true);
  };

  if (cookieAccepted) return null; // Hide the banner if cookies are accepted or closed

  return (
    <div className="w-full fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-primary bg-opacity-90 border-0 rounded-xl p-6 z-50">
      <span
        onClick={closeBanner}
        className="absolute top-2 right-4 text-2xl font-bold cursor-pointer"
      >
        <RxCross1/>
      </span>
      <div className="text-center mb-4">
        <strong>Gestisci il consenso dei cookie</strong>
      </div>
      <p className="text-center text-sm mb-4">
        Utilizziamo tecnologie come i cookie per memorizzare e/o accedere alle informazioni del dispositivo.
        Facciamo ciò per migliorare l'esperienza di navigazione e per mostrare annunci personalizzati.
      </p>
      <div className="flex justify-center gap-4">
        <button
          onClick={acceptCookies}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold text-lg"
        >
          Accetta
        </button>
        <button
          onClick={rejectCookies}
          className="bg-gray-500 text-white px-6 py-2 rounded-lg font-semibold text-lg"
        >
          Rifiuta
        </button>
      </div>
      <div className="text-center mt-3">
        <Link
          to="https://policies.google.com/technologies/cookies?hl=it"
          className="text-sm text-blue-500"
        >
          Cookie Policy
        </Link>
        <span className="mx-2">|</span>
        <Link
          to="/privacy-policy"
          className="text-sm text-blue-500"
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default CookieBanner;
