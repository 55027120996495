import React from "react";
import { useSelector } from "react-redux";
import RoomCard from "./RoomCard";
import { IoBedOutline } from "../assets/icons/index";
import {
  ReviewsSlider,
  GallerySection,
  ContactForm,
  Faq,
  ParnerSection,
  WhyChooseUs,
  MetaDescription,
} from "../components";

const Room = () => {
  const rooms = useSelector((state) => state.rooms.rooms || []);

  return (
    <div className="rounded-xl min-h-screen w-full lg:max-w-7xl">
      <MetaDescription
        title="B&B Piazza d'Armi a Lamezia Terme | Le Camere"
        description="Soggiorna nelle nostre camere confortevoli al B&B Piazza d'Armi. Ideale per coppie e famiglie. Servizi esclusivi e relax nel cuore di Lamezia Terme."
        image="./public/images/fronte-piatto.jpg"
        url="https://www.bebpiazzadarmi.it/camere"
      />
      <div className="mx-auto">
        {/* Header Section */}
        <div className=" bg-white py-10 px-6 rounded-xl shadow-lg mb-2">
          <h1 className="text-3xl md:text-4xl text-center font-bold text-headingColor">
            <IoBedOutline className="text-center w-full text-6xl" /> Le Nostre
            Camere
          </h1>
        </div>
        <p className="text-lg md:text-xl font-normal mb-4">
          Scopri le nostre splendide camere, progettate per offrirti il massimo
          comfort durante il tuo soggiorno.
        </p>

        {/* Room Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {rooms.length > 0 ? (
            rooms.map((room, index) => (
              <RoomCard
                key={room.roomId}
                room={room}
                className={index >= 3 ? "col-span-1 mx-auto" : ""}
              />
            ))
          ) : (
            <p className="col-span-full text-center text-gray-600 text-lg">
              Nessuna stanza disponibile
            </p>
          )}
        </div>
      </div>
      <div className="mb-16 flex">
        <WhyChooseUs />
      </div>
      <div className="mb-16 flex text-center justify-center">
        <ReviewsSlider />
      </div>
      <div className="mb-16 flex text-center justify-center">
        <ParnerSection />
      </div>
      <div className="mb-16">
        <Faq />
      </div>
      <div className="mb-16">
        <GallerySection />
      </div>
      <div className="mb-16">
        <ContactForm />
      </div>
    </div>
  );
};

export default Room;
