import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';  // Importa Link per navigazione dinamica
import { getAllUsers, getAllRooms, getAllBookings, getAllContacts, getAllReviews } from "../api";
import { setAllRooms } from "../context/actions/roomActions";
import { setAllUserDetails } from "../context/actions/allUsersAction";
import { setAllContacts } from "../context/actions/contactAction";
import { setAllReviews } from "../context/actions/reviewAction";
import { setBookings } from '../context/actions/bookingsAction';
import {FaArrowRight} from "../assets/icons";

const DBHome = () => {
  const dispatch = useDispatch();

  // Selezione dello stato globale con useSelector
  const allUsers = useSelector((state) => state.allUsers);
  const allRooms = useSelector((state) => state.rooms.rooms);
  const allBookings = useSelector((state) => state.bookings);
  const allContacts = useSelector((state) => state.contacts.contacts);
  const allReviews = useSelector((state) => state.reviews.reviews || []);

  useEffect(() => {
    if (!allUsers || allUsers.length === 0) {
      getAllUsers().then((data) => {
        dispatch(setAllUserDetails(data));
      });
    }

    if (!allRooms || allRooms.length === 0) {
      getAllRooms().then((data) => {
        dispatch(setAllRooms(data));
      });
    }

    if (!allBookings || allBookings.length === 0) {
      getAllBookings().then((data) => {
        dispatch(setBookings(data));
      });
    }

    if (!allContacts || allContacts.length === 0) {
      getAllContacts().then((data) => {
        dispatch(setAllContacts(data));
      });
    }

    if (!allReviews || allReviews.length === 0) {
      getAllReviews().then((data) => {
        dispatch(setAllReviews(data));
      });
    }
  }, [allUsers, allRooms, allBookings, allContacts, allReviews, dispatch]);

  // Funzioni di calcolo con controlli di sicurezza
  const calculateTotalUsers = (users) => (Array.isArray(users) ? users.length : 0);
  const calculateTotalBookings = (bookings) => {
    if (!Array.isArray(bookings)) return { totalBookings: 0, totalAmount: 0 };
    const totalBookings = bookings.length;
    const totalAmount = bookings.reduce((sum, booking) => sum + (booking.amount || 0), 0);
    return { totalBookings, totalAmount };
  };
  const calculateTotalContacts = (contacts) => (Array.isArray(contacts) ? contacts.length : 0);
  const calculateReviewStats = (reviews) => {
    if (!Array.isArray(reviews) || reviews.length === 0) {
      return { averageRating: 0, totalReviews: 0 };
    }
    const totalReviews = reviews.length;
    const averageRating = reviews.reduce((sum, review) => sum + (review.rating || 0), 0) / totalReviews;
    return { averageRating, totalReviews };
  };
  const calculateAvailableRooms = (rooms) => (Array.isArray(rooms) ? rooms.length : 0);

  // Calcoli dei dati
  const totalUsers = calculateTotalUsers(allUsers);
  const { totalBookings, totalAmount } = calculateTotalBookings(allBookings);
  const totalContacts = calculateTotalContacts(allContacts);
  const { averageRating, totalReviews } = calculateReviewStats(allReviews);
  const availableRooms = calculateAvailableRooms(allRooms);

  // Formattazione dell'importo totale
  const formattedTotalAmount = (totalAmount / 100).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {/* Card Utenti */}
      <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
        <h3 className="text-lg font-semibold text-gray-700">Utenti Iscritti</h3>
        <p className="text-3xl font-bold text-blue-500">{totalUsers}</p>
        <Link to="/dashboard/users" className="mt-4 bg-headingColor p-2 rounded-xl text-white hover:underline flex items-center">
          Vai alla gestione utenti
          <FaArrowRight className="h-5 w-5 ml-2 text-white" />
        </Link>
      </div>

      {/* Card Prenotazioni */}
      <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
        <h3 className="text-lg font-semibold text-gray-700">Prenotazioni</h3>
        <p className="text-3xl font-bold text-green-500">{totalBookings}</p>
        <p className="text-xl text-gray-600">Guadagno Totale: {formattedTotalAmount}</p>
        <Link to="/dashboard/bookings" className="mt-4 bg-headingColor p-2 rounded-xl text-white hover:underline flex items-center">
          Vai alla gestione prenotazioni
          <FaArrowRight className="h-5 w-5 ml-2 text-white" />
        </Link>
      </div>

      {/* Card Messaggi Ricevuti */}
      <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
        <h3 className="text-lg font-semibold text-gray-700">Messaggi Ricevuti</h3>
        <p className="text-3xl font-bold text-orange-500">{totalContacts}</p>
        <Link to="/dashboard/contacts" className="mt-4 bg-headingColor p-2 rounded-xl text-white hover:underline flex items-center">
          Vai alla gestione messaggi
          <FaArrowRight className="h-5 w-5 ml-2 text-white" />
        </Link>
      </div>

      {/* Card Recensioni */}
      <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
        <h3 className="text-lg font-semibold text-gray-700">Recensioni</h3>
        <p className="text-3xl font-bold text-yellow-500">{totalReviews}</p>
        <p className="text-xl text-gray-600">Voto Medio: {averageRating}</p>
        <Link to="/dashboard/reviews" className="mt-4 bg-headingColor p-2 rounded-xl text-white hover:underline flex items-center">
          Vai alla gestione recensioni
          <FaArrowRight className="h-5 w-5 ml-2 text-white" />
        </Link>
      </div>

      {/* Card Stanze Disponibili */}
      <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
        <h3 className="text-lg font-semibold text-gray-700">Stanze Disponibili</h3>
        <p className="text-3xl font-bold text-purple-500">{availableRooms}</p>
        <Link to="/dashboard/rooms" className="mt-4 bg-headingColor p-2 rounded-xl text-white hover:underline flex items-center">
          Vai alla gestione stanze
          <FaArrowRight className="h-5 w-5 ml-2 text-white" />
        </Link>
      </div>
    </div>
  );
};

export default DBHome;
