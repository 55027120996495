import React from "react";

import {
  IoBedOutline,
  GiChiliPepper,
  MdLocationOn,
  MdOutlineRoomService,
} from "../assets/icons/index";

const WhyChooseUs = () => {
  return (
    <section className="py-8 px-6 md:px-8 lg:px-16 shadow-lg bg-white rounded-2xl">
      <h2 className="text-2xl md:text-3xl font-bold text-headingColor text-center mb-6">
        Perché scegliere il nostro B&B a Lamezia Terme?
      </h2>
      <div className="space-y-6 text-gray-700">
        {/* Posizione Strategica */}
        <div className="flex items-start gap-4">
          <div className="bg-headingColor shadow-md p-3 rounded-full flex items-center justify-center">
            <MdLocationOn className="text-white text-2xl" />
          </div>
          <div>
            <h3 className="text-xl text-headingColor font-semibold">
              Posizione Strategica
            </h3>
            <p className="mt-2">
              Situato a breve distanza dall’aeroporto, dalla stazione
              ferroviaria e dal centro storico, il nostro B&B è perfetto per chi
              viaggia per lavoro o piacere.
            </p>
          </div>
        </div>

        {/* Camere Confortevoli */}
        <div className="flex items-start gap-4">
          <div className="bg-headingColor shadow-md p-3 rounded-full flex items-center justify-center">
            <IoBedOutline className="text-white text-2xl" />
          </div>
          <div>
            <h3 className="text-xl text-headingColor font-semibold">
              Camere Confortevoli
            </h3>
            <p className="mt-2">
              Ogni stanza è curata nei minimi dettagli, con letti comodi, Wi-Fi
              gratuito e tutto il necessario per farti sentire come a casa.
            </p>
          </div>
        </div>

        {/* Servizio Personalizzato */}
        <div className="flex items-start gap-4">
          <div className="bg-headingColor shadow-md p-3 rounded-full flex items-center justify-center">
            <MdOutlineRoomService className="text-white text-2xl" />
          </div>
          <div>
            <h3 className="text-xl text-headingColor font-semibold">
              Servizio Personalizzato
            </h3>
            <p className="mt-2">
              Ci impegniamo a offrirti un’esperienza su misura, che tu sia qui
              per una vacanza, un breve soggiorno o un viaggio di lavoro.
            </p>
          </div>
        </div>

        {/* Sapori della Calabria */}
        <div className="flex items-start gap-4">
          <div className="bg-headingColor shadow-md p-3 rounded-full flex items-center justify-center">
            <GiChiliPepper className="text-white text-2xl" />
          </div>
          <div>
            <h3 className="text-xl text-headingColor font-semibold">
              Sapori della Calabria
            </h3>
            <p className="mt-2">
              Vivi un’esperienza culinaria unica con i ristoranti in città, che
              offrono piatti autentici della tradizione calabrese. Assapora
              specialità come la ‘nduja, i salumi locali, i formaggi freschi, e
              una vasta selezione di vini pregiati. Che tu preferisca un pasto
              elegante o uno spuntino veloce, avrai a disposizione un’ampia
              gamma di opzioni per soddisfare ogni gusto.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
